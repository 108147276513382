export const environment = {
  production: false,
  development: false,
  local: false,
  homolog: true,
  apiKey: 'homolKey',
  apiUrl: 'https://api.homol.make-platform.com/',
  bundleUrl: 'https://mf-bpmn.homol.make-platform.com/bundle.js',
  tls_cert: 'tls-cert-qas.pem',
  tls_key: 'tls-key-qas.pem'
}
